import React from "react"
import {Helmet} from "react-helmet"
import Layout from "../../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
//import Row from 'react-bootstrap/Row'
//import Col from 'react-bootstrap/Col'
import "../../styles/styles.scss"
import roomsStyles from "./rooms.module.scss"
import Logo from "../../components/logo"
import "intersection-observer"
import leftCorner from "../../images/decorative-corner-left.svg"
//Lightbox//
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import ConcatedOptions from "../../components/lightbox";
import "../../components/lightbox.module.scss";
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMale,  faExpandArrowsAlt, faBed, faUtensils, faWifi, faTv, faShower, faThermometerQuarter, faSun, faCouch} from '@fortawesome/free-solid-svg-icons'

const RoomsPage = () => (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pl" />
        <title>Pokoje | Pensjonat U Wierzby Pod Lasem</title>
        <meta name="description" content="Pensjonat U Wierzby Pod Lasem oferuje komfortowe pokoje 1,2,3,4,5-osobowe z łazienkami. Zapraszamy."/>
        <link rel="canonical" href="https://www.uwierzby.pl/pokoje/"/>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Charm&family=Cormorant+SC:wght@300&family=Quicksand&display=swap" as="style"></link>
      </Helmet>
      <Layout>
        <section className={`JumbotronSection ${roomsStyles.JumbotronSection___rooms}`}>
          <Jumbotron fluid className={`Jumbotron`}>
            <div className={`Jumbotron_overlay`}></div>
            <div className={`Jumbotron_logo`}>
              <Logo/>
            </div>
            <Container fluid className={`Jumbotron_container`}>
              <h1 className="animate__animated animate__slideInLeft">pokoje</h1>
              <h3 className={`SectionSubTitleH3 light animate__animated animate__slideInRight`}>tradycja i komfort</h3>
            </Container>
          </Jumbotron>
        </section>
        <section className={`BreadcrumbSection`}>
          <Breadcrumb className={`Breadcrumb`}>
              <Breadcrumb.Item className={`Breadcrumb_item`} href="../">Strona Główna</Breadcrumb.Item>
              <Breadcrumb.Item className={`Breadcrumb_item`} active>Pokoje</Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <section className={`MainSection`}>
        <Container fluid className={`MainSection_wrapper`}>
          <img className={`MainSection_corner___left`} src={leftCorner} alt="top left decorative corner"></img>
          <div className={`MainSection_textWrapper`}>
            <div className={`SectionTitle_wrapper`}>
              <div className={`SectionTitle_bigLetter`}><p>P</p></div>
              <h1 className={`SectionTitle`}>pokoje</h1>
              <h3 className={`SectionSubTitleH3`}>coś dla wszystkich</h3>
            </div>
            <div className={roomsStyles.introText}>Pensjonat U Wierzby Pod Lasem to niezwykłe miejsce w pięknym otoczeniu, a domowa atmosfera sprawi, że spędzicie Państwo tutaj przemiłe chwile. Poniżej przedstawiamy ofertę naszych noclegów.</div>
            <div className={`MainSection_main gallery rooms`}>
                  <div className={`MainSection_mainText`}>
                    <div className={roomsStyles.roomType}>
                      <div className={roomsStyles.roomGallery}>
                        <SimpleReactLightbox>
                          <div className={`${roomsStyles.RoomGallery_fotoGallery}`}>
                            <SRLWrapper options={ConcatedOptions}>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr1/pokoj-nr1-photo09.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr1/pokoj-nr1-photo09-miniaturka.jpg")} alt="pokój nr 1"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr1/pokoj-nr1-photo01.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr1/pokoj-nr1-photo01.jpg")} alt="pokój nr 1"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr1/pokoj-nr1-photo02.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr1/pokoj-nr1-photo02.jpg")} alt="pokój nr 1"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr1/pokoj-nr1-photo03.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr1/pokoj-nr1-photo03.jpg")} alt="pokój nr 1"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr1/pokoj-nr1-photo04.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr1/pokoj-nr1-photo04.jpg")} alt="pokój nr 1"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr1/pokoj-nr1-photo05.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr1/pokoj-nr1-photo05.jpg")} alt="pokój nr 1"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr1/pokoj-nr1-photo06.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr1/pokoj-nr1-photo06.jpg")} alt="pokój nr 1"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr1/pokoj-nr1-photo07.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr1/pokoj-nr1-photo07.jpg")} alt="pokój nr 1"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr1/pokoj-nr1-photo08.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr1/pokoj-nr1-photo08.jpg")} alt="pokój nr 1"></img>
                                  </a>
                            </SRLWrapper>
                          </div>
                        </SimpleReactLightbox>
                      </div>
                      <div className={roomsStyles.roomDesc}>
                        <div className={roomsStyles.roomDesc_title}>Pokój Nr 1</div>
                        <div className={`sep-clear ${roomsStyles.roomDesc_sepClear}`}></div>
                        <div>
                          <div className={roomsStyles.roomDesc_icons__underTitle}>
                            <FontAwesomeIcon icon={faMale} className={roomsStyles.descIcon_underTitle}/>
                            <FontAwesomeIcon icon={faMale} className={roomsStyles.descIcon_underTitle}/> 5 osób
                          </div>
                          <div className={roomsStyles.roomDesc_icons__underTitle}>
                            <FontAwesomeIcon icon={faExpandArrowsAlt} className={roomsStyles.descIcon_underTitle}/> 30 m<sup>2</sup>
                          </div>
                        </div>
                        <div className={roomsStyles.roomDesc_desc}>
                          <p className="text-justify">Pokój w starym stylu - z belką z wyrytą datą budowy pensjonatu.
                            Jest to przestronny pokój dla 5 osób, o powierzchni 30 m<sup>2</sup>.
                            Wyposażenie: 1 x łóżko dwuosobowe, 3 x łóżko pojedyncze; szafa, komoda, wypoczynek z ławą, stoliki, TV, łazienka przy pokoju.</p>
                          <div className={roomsStyles.roomDesc_icons}>
                            <div className={roomsStyles.roomDesc_icons___left}>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faBed} className={roomsStyles.descIcon}/>
                                </span> 1 x dwuosobowe
                              </p>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faBed} className={roomsStyles.descIcon}/>
                                </span> 3 x jednoosobowe
                              </p>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faTv} className={roomsStyles.descIcon}/>
                                </span> TV satelitarna
                              </p>
                            </div>
                            <div className={roomsStyles.roomDesc_icons___right}>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faShower} className={roomsStyles.descIcon}/>
                                </span> łazienka
                              </p>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faWifi} className={roomsStyles.descIcon}/>
                                </span> internet WiFi
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <hr className="sep-clear-long"></hr>

                    <div className={roomsStyles.roomType}>
                      <div className={roomsStyles.roomGallery}>
                        <SimpleReactLightbox>
                          <div className={`${roomsStyles.RoomGallery_fotoGallery}`}>
                            <SRLWrapper options={ConcatedOptions}>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr2/pokoj-nr6-photo01.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr2/pokoj-nr6-photo01-miniaturka.jpg")} alt="pokój nr 2"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr2/pokoj-nr6-photo02.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr2/pokoj-nr6-photo02.jpg")} alt="pokój nr 2"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr2/pokoj-nr6-photo03.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr2/pokoj-nr6-photo03.jpg")} alt="pokój nr 2"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr2/pokoj-nr6-photo04.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr2/pokoj-nr6-photo04.jpg")} alt="pokój nr 2"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr2/pokoj-nr6-photo05.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr2/pokoj-nr6-photo05.jpg")} alt="pokój nr 2"></img>
                                  </a>
                                  <a  className={`${roomsStyles.FotoGallery_item}`}
                                    href={require('../../images/pokoje/nr2/pokoj-nr6-photo06.jpg')} data-attribute="SRL">
                                    <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr2/pokoj-nr6-photo06.jpg")} alt="pokój nr 2"></img>
                                  </a>
                            </SRLWrapper>
                          </div>
                        </SimpleReactLightbox>
                      </div>
                      <div className={roomsStyles.roomDesc}>
                        <div className={roomsStyles.roomDesc_title}>Pokój Nr 2</div>
                        <div className={`sep-clear ${roomsStyles.roomDesc_sepClear}`}></div>
                        <div>
                          <div className={roomsStyles.roomDesc_icons__underTitle}>
                            <FontAwesomeIcon icon={faMale} className={roomsStyles.descIcon_underTitle}/>
                            <FontAwesomeIcon icon={faMale} className={roomsStyles.descIcon_underTitle}/> 2 - 4 osoby
                          </div>
                        </div>
                        <div className={roomsStyles.roomDesc_desc}>
                          <p className="text-justify">Pokój 2-4 osobowy, <strong>apartament</strong>, na piętrze <strong>z osobnym wyjściem na zewnątrz</strong>, tarasem, łazienką i aneksem kuchennym.
                          Wyposażenie: 2 x łóżko dwuosobowe, 1 x dostawka; klimatyzacja, TV, WiFi, lodówka, mikrofala, czajnik bezprzewodowy, szafka i zlew kuchenny z pełnym wyposażeniem w naczynia kuchenne.</p>
                          <div className={roomsStyles.roomDesc_icons}>
                            <div className={roomsStyles.roomDesc_icons___left}>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faBed} className={roomsStyles.descIcon}/>
                                </span> 2 x dwuosobowe
                              </p>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faBed} className={roomsStyles.descIcon}/>
                                </span> 1 x dostawka
                              </p>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faTv} className={roomsStyles.descIcon}/>
                                </span> TV satelitarna
                              </p>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faUtensils} className={roomsStyles.descIcon}/>
                                </span> aneks kuchenny
                              </p>
                            </div>
                            <div className={roomsStyles.roomDesc_icons___right}>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faShower} className={roomsStyles.descIcon}/>
                                </span> łazienka
                              </p>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faWifi} className={roomsStyles.descIcon}/>
                                </span> internet WiFi
                              </p>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faThermometerQuarter} className={roomsStyles.descIcon}/>
                                </span> lodówka
                              </p>
                              <p className={roomsStyles.descIconWrapper}>
                                <span>
                                  <FontAwesomeIcon icon={faSun} className={roomsStyles.descIcon}/>
                                </span> klimatyzacja
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <hr className="sep-clear-long"></hr>

                    <div className={roomsStyles.roomType}>
                        <div className={roomsStyles.roomGallery}>
                          <SimpleReactLightbox>
                            <div className={`${roomsStyles.RoomGallery_fotoGallery}`}>
                              <SRLWrapper options={ConcatedOptions}>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr3/pokoj-nr3-photo02.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr3/pokoj-nr3-photo02-miniaturka.jpg")} alt="pokój nr 3"></img>
                                    </a>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr3/pokoj-nr3-photo01.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr3/pokoj-nr3-photo01.jpg")} alt="pokój nr 3"></img>
                                    </a>
                              </SRLWrapper>
                            </div>
                          </SimpleReactLightbox>
                        </div>
                        <div className={roomsStyles.roomDesc}>
                          <div className={roomsStyles.roomDesc_title}>Pokój Nr 3</div>
                          <div className={`sep-clear ${roomsStyles.roomDesc_sepClear}`}></div>
                          <div>
                          <div className={roomsStyles.roomDesc_icons__underTitle}>
                            <FontAwesomeIcon icon={faMale} className={roomsStyles.descIcon_underTitle}/>
                            <FontAwesomeIcon icon={faMale} className={roomsStyles.descIcon_underTitle}/> 2 - 3 osoby
                          </div>
                        </div>
                          <div className={roomsStyles.roomDesc_desc}>
                            <p className="text-justify">Pokój 2-3 osobowy z łazienką.
                              Wyposażenie: 1 x duże łóżko dwuosobowe, 1 x kanapa rozkładana, TV, szafa, komoda, stolik, fotel.</p>
                            <div className={roomsStyles.roomDesc_icons}>
                              <div className={roomsStyles.roomDesc_icons___left}>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faBed} className={roomsStyles.descIcon}/>
                                  </span> 1 x dwuosobowe
                                </p>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faCouch} className={roomsStyles.descIcon}/>
                                  </span> 1 x rozkładana sofa
                                </p>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faTv} className={roomsStyles.descIcon}/>
                                  </span> TV satelitarna
                                </p>
                              </div>
                              <div className={roomsStyles.roomDesc_icons___right}>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faShower} className={roomsStyles.descIcon}/>
                                  </span> łazienka
                                </p>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faWifi} className={roomsStyles.descIcon}/>
                                  </span> internet WiFi
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr className="sep-clear-long"></hr>

                      <div className={roomsStyles.roomType}>
                        <div className={roomsStyles.roomGallery}>
                          <SimpleReactLightbox>
                            <div className={`${roomsStyles.RoomGallery_fotoGallery}`}>
                              <SRLWrapper options={ConcatedOptions}>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr4/pokoj-nr4-photo01.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr4/pokoj-nr4-photo01-miniaturka.jpg")} alt="pokój nr 4"></img>
                                    </a>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr4/pokoj-nr4-photo02.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr4/pokoj-nr4-photo02.jpg")} alt="pokój nr 4"></img>
                                    </a>
                              </SRLWrapper>
                            </div>
                          </SimpleReactLightbox>
                        </div>
                        <div className={roomsStyles.roomDesc}>
                          <div className={roomsStyles.roomDesc_title}>Pokój Nr 4</div>
                          <div className={`sep-clear ${roomsStyles.roomDesc_sepClear}`}></div>
                          <div>
                          <div className={roomsStyles.roomDesc_icons__underTitle}>
                            <FontAwesomeIcon icon={faMale} className={roomsStyles.descIcon_underTitle}/>
                            <FontAwesomeIcon icon={faMale} className={roomsStyles.descIcon_underTitle}/> 2 osoby
                          </div>
                        </div>
                          <div className={roomsStyles.roomDesc_desc}>
                            <p className="text-justify">Pokój 2-osobowy.
                              Wyposażenie: 2 x łóżka jednoosobowe, TV, szafa, komoda, stolik, fotel.
                              Łazienka do pokoju, przy pokoju.</p>
                            <div className={roomsStyles.roomDesc_icons}>
                              <div className={roomsStyles.roomDesc_icons___left}>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faBed} className={roomsStyles.descIcon}/>
                                  </span> 2 x jednoosobowe
                                </p>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faTv} className={roomsStyles.descIcon}/>
                                  </span> TV satelitarna
                                </p>
                              </div>
                              <div className={roomsStyles.roomDesc_icons___right}>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faShower} className={roomsStyles.descIcon}/>
                                  </span> łazienka
                                </p>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faWifi} className={roomsStyles.descIcon}/>
                                  </span> internet WiFi
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr className="sep-clear-long"></hr>

                      <div className={roomsStyles.roomType}>
                        <div className={roomsStyles.roomGallery}>
                          <SimpleReactLightbox>
                            <div className={`${roomsStyles.RoomGallery_fotoGallery}`}>
                              <SRLWrapper options={ConcatedOptions}>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr5/pokoj-nr5-photo01.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr5/pokoj-nr5-photo01-miniaturka.jpg")} alt="pokój nr 5"></img>
                                    </a>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr5/pokoj-nr5-photo02.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr5/pokoj-nr5-photo02.jpg")} alt="pokój nr 5"></img>
                                    </a>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr5/pokoj-nr5-photo03.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr5/pokoj-nr5-photo03.jpg")} alt="pokój nr 5"></img>
                                    </a>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr5/pokoj-nr5-photo04.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr5/pokoj-nr5-photo04.jpg")} alt="pokój nr 5"></img>
                                    </a>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr5/pokoj-nr5-photo05.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr5/pokoj-nr5-photo05.jpg")} alt="pokój nr 5"></img>
                                    </a>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr5/pokoj-nr5-photo06.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr5/pokoj-nr5-photo06.jpg")} alt="pokój nr 5"></img>
                                    </a>
                                    <a  className={`${roomsStyles.FotoGallery_item}`}
                                      href={require('../../images/pokoje/nr5/pokoj-nr5-photo07.jpg')} data-attribute="SRL">
                                      <img className={roomsStyles.FotoGallery_itemImg} src={require("../../images/pokoje/nr5/pokoj-nr5-photo07.jpg")} alt="pokój nr 5"></img>
                                    </a>
                              </SRLWrapper>
                            </div>
                          </SimpleReactLightbox>
                        </div>
                        <div className={roomsStyles.roomDesc}>
                          <div className={roomsStyles.roomDesc_title}>Pokój Nr 5</div>
                          <div className={`sep-clear ${roomsStyles.roomDesc_sepClear}`}></div>
                          <div>
                          <div className={roomsStyles.roomDesc_icons__underTitle}>
                            <FontAwesomeIcon icon={faMale} className={roomsStyles.descIcon_underTitle}/>
                            <FontAwesomeIcon icon={faMale} className={roomsStyles.descIcon_underTitle}/> 2 osoby
                          </div>
                        </div>
                          <div className={roomsStyles.roomDesc_desc}>
                            <p className="text-justify">Pokój 2-osobowy z łazienką z osobnym wyjściem na zewnątrz.
                              Wyposażenie: 1 x łożko dwuosobowe, TV, stół, stoliki, fotel, czajnik bezprzewodowy.</p>
                            <div className={roomsStyles.roomDesc_icons}>
                              <div className={roomsStyles.roomDesc_icons___left}>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faBed} className={roomsStyles.descIcon}/>
                                  </span> 1 x dwuosobowe
                                </p>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faTv} className={roomsStyles.descIcon}/>
                                  </span> TV satelitarna
                                </p>
                              </div>
                              <div className={roomsStyles.roomDesc_icons___right}>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faShower} className={roomsStyles.descIcon}/>
                                  </span> łazienka
                                </p>
                                <p className={roomsStyles.descIconWrapper}>
                                  <span>
                                    <FontAwesomeIcon icon={faWifi} className={roomsStyles.descIcon}/>
                                  </span> internet WiFi
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                  <div className={`SectionTitle_wrapper`}>
                      <div className={`SectionTitle_bigLetter`}><p>C</p></div>
                      <h2 className={`SectionSubTitleH2`}>cennik</h2>
                      <h3 className={`SectionSubTitleH3`}>jakość za przystępną cenę</h3>
                  </div>
                  <div className={`MainSection_mainText`}>
                    <p>Koszt noclegu mieści się w granicach: <strong>45 - 60 zł</strong>/osoba/doba</p>
                    <p>Koszt apartamentu (pokój nr 2) to 120 zł doba/za 2 osoby.</p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </Layout>
  </>
);

export default RoomsPage
